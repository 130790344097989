var Helpers = (function () {
  function throttle(fn, threshhold, scope) {
    threshhold || (threshhold = 250);
    var last, deferTimer;
    return function () {
      var context = scope || this;
      var now = +new Date(),
        args = arguments;
      if (last && now < last + threshhold) {
        // hold on to it
        clearTimeout(deferTimer);
        deferTimer = setTimeout(function () {
          last = now;
          fn.apply(context, args);
        }, threshhold);
      } else {
        last = now;
        fn.apply(context, args);
      }
    };
  }

  function isIE() {
    var ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
    var msie = ua.indexOf("MSIE "); // IE 10 or older
    var trident = ua.indexOf("Trident/"); //IE 11

    return msie > 0 || trident > 0;
  }

  function fixObjectFit() {
    if ("objectFit" in document.documentElement.style === false) {
      $("img[data-object-fit]").each(function () {
        var imgUri = $(this)
          .attr("src")
          .replace(/\(/g, "%28")
          .replace(/\)/g, "%29");
        $(this)
          .css({
            backgroundImage: "url(" + imgUri + ")",
            backgroundPosition: "center",
            backgroundSize: $(this).attr("data-object-fit"),
          })
          .attr(
            "src",
            "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='" +
              $(this).width() +
              "' height='" +
              $(this).height() +
              "'%3E%3C/svg%3E"
          )
          .removeAttr("data-object-fit");
      });
    }
  }

  function onElementHeightChange(elm, callback) {
    var lastHeight = elm.clientHeight,
      newHeight;
    (function run() {
      newHeight = elm.clientHeight;
      if (lastHeight != newHeight) callback();
      lastHeight = newHeight;

      if (elm.onElementHeightChangeTimer)
        clearTimeout(elm.onElementHeightChangeTimer);

      elm.onElementHeightChangeTimer = setTimeout(run, 200);
    })();
  }

  function generateRandomInteger(min, max) {
    return Math.floor(min + Math.random() * (max + 1 - min));
  }

  function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split("&"),
      sParameterName,
      i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split("=");

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined
          ? true
          : decodeURIComponent(sParameterName[1]);
      }
    }
  }

  function setUrlParameter(query) {
    if (history.pushState) {
      var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?" +
        query;
      window.history.pushState({ path: newurl }, "", newurl);
    }
  }

  return {
    throttle: function (fn, threshhold, scope) {
      return throttle(fn, threshhold, scope);
    },
    isIE: function () {
      return isIE();
    },
    fixObjectFit: function () {
      return fixObjectFit();
    },
    onElementHeightChange: function (elm, callback) {
      return onElementHeightChange(elm, callback);
    },
    generateRandomInteger(min, max) {
      return generateRandomInteger(min, max);
    },
    getUrlParameter(sParam) {
      return getUrlParameter(sParam);
    },
    setUrlParameter(query) {
      return setUrlParameter(query);
    },
  };
})();

(function ($) {
  "use strict";

  var $html = $("html"),
    isTouch = $html.hasClass("touchevents"),
    $body = $("body");
  var windowWidth = Math.max($(window).width(), window.innerWidth);
  var locoScroll;
  var resizeEnd;

  /*flatpickr*/
  if (typeof flatpickr !== "undefined") {
    flatpickr.localize(flatpickr.l10ns[window.locale ? window.locale : "tr"]);
  }

  /*cookie-policy-toast*/
  if ($(".cookie-policy-toast").length > 0) {
    $(".cookie-policy-toast").toast("show");
  }

  /*Title animate*/
  if ($(".unfold-animate").length > 0) {
    $(".unfold-animate").each(function () {
      var thisText = $(this).html();

      var thisHtml =
        '<span class="mask"><span>' +
        thisText
          .split(/ (?=[^>]*(?:<|$))/)
          .join('</span></span> <span class="mask"><span>') +
        "</span></span>";
      $(this).html(thisHtml);
      var delay = -1,
        prev_offset = 0;
      $(this)
        .find(".mask")
        .each(function () {
          var offset = $(this).position().top;
          if (prev_offset != offset) {
            prev_offset = offset;
            delay++;
          }
          $(this).addClass("delay" + delay);
        });

      $(this)
        .find("span")
        .each(function () {
          if ($(this).find("br").length > 0) {
            $(this).after("<br />").find("br").remove();
          }
        });
    });
  }

  /*Page transitions*/
  if ($body.hasClass("location-animate")) {
    setTimeout(function () {
      $(".location-animate").addClass("page-visible");
      if (!$html.hasClass("preload-page")) {
        $html.addClass("page-load");
      }
    }, 500);
  }

  var pageTransitions = true;
  $body.on(
    "click",
    'a[href^="mailto"], a[href^="tel"], a[href^="viber"], .js-open-gallery',
    function () {
      pageTransitions = false;
      clearTimeout(resizeEnd);
      resizeEnd = setTimeout(function () {
        pageTransitions = true;
      }, 50);
    }
  );
  $body.on("click", "a", function (e) {
    if (
      $(this).closest("#bx-panel").length ||
      $(this).closest(".bx-component-opener").length
    ) {
      return;
    }
    if (
      pageTransitions &&
      $(this).attr("target") != "_blank" &&
      !$(this).hasClass("prevent-transitions") &&
      !$(this).hasClass("disabled") &&
      !$(this).hasClass("js-load-more")
    ) {
      e.preventDefault();
      var newLocation = $(this).attr("href");
      $(".location-animate").removeClass("page-visible");

      setTimeout(function () {
        window.location = newLocation;
      }, 200);
    }
  });

  /*Header*/
  var scrollDefaultPosition = 0;
  function stickyHeader(wST) {
    if (wST > scrollDefaultPosition) {
      $html.addClass("sticky-header short-header-view");
    }

    if (scrollDefaultPosition > wST) {
      $html.removeClass("short-header-view");
    }

    if (wST <= 1) {
      if ($html.hasClass("sticky-header")) {
        $html.removeClass("sticky-header");
      }
    }

    scrollDefaultPosition = wST;
  }
  stickyHeader(0);

  /* header base nav first and last item padding fix */
  if (
    $(".tp-header .nav.nav-first-level > .nav-item:not(.d-lg-none)").length > 0
  ) {
    $(".tp-header .nav.nav-first-level > .nav-item:not(.d-lg-none)")
      .first()
      .find(".nav-link")
      .addClass("pl-lg-0");
    $(".tp-header .nav.nav-first-level > .nav-item:not(.d-lg-none)")
      .last()
      .find(".nav-link")
      .addClass("pr-lg-0");
  }

  /*mobile header search*/
  var $mobileSearchWrapper = $(".mobile-search-wrapper"),
    $mobileSearchInput = $(".mobile-search-input"),
    $mobileSearchButton = $(".mobile-search-button");

  function submitMobileSearch() {
    var url = $mobileSearchWrapper.data("path");
    if (url && $mobileSearchInput.val().length > 0) {
      location.href = url + $mobileSearchInput.val();
    }
  }

  $mobileSearchInput.keypress(function (e) {
    if (e.which == 13) {
      submitMobileSearch();
      return false;
    }
  });
  $mobileSearchButton.on("click", function (e) {
    submitMobileSearch();
    return false;
  });

  /*header search*/
  var $searchWrapper = $(".search-wrapper"),
    $searchInput = $(".search-input"),
    $searchButton = $(".search-button");

  function submitSearch() {
    var url = $searchWrapper.data("path");
    if (url && $searchInput.val().length > 0) {
      location.href = url + $searchInput.val();
    }
  }

  $searchInput.keypress(function (e) {
    if (e.which == 13) {
      submitSearch();
      return false;
    }
  });
  $(document).on("click", function (e) {
    if ($(e.target).is($searchButton) && $searchWrapper.hasClass("focused")) {
      submitSearch();
      return false;
    }
    if ($.contains($searchWrapper[0], e.target)) {
      $searchWrapper.addClass("focused");
      $searchInput.focus();
    } else {
      $searchWrapper.removeClass("focused");
      $searchInput.val("");
    }
  });

  /*sticky table header*/
  var $stickyTable = $(".table-sticky-header");
  var $stickyTableHead = $(".table-sticky-header thead");
  var $globalHeader = $(".tp-header");

  function stickyTableHeader(wST) {
    if ($stickyTable.length > 0 && $stickyTableHead.length > 0) {
      var topOffset = $globalHeader.height();
      var bottomOffset = 250;

      if (windowWidth > 991 && $html.hasClass("short-header-view")) {
        topOffset = topOffset - 55;
      }

      if (
        $stickyTable.offset().top < topOffset &&
        $stickyTable.height() + $stickyTable.offset().top > bottomOffset
      ) {
        $stickyTableHead.addClass("sticky");
        $stickyTableHead[0].style.transform =
          "translateY(" + (topOffset - $stickyTable.offset().top) + "px)";
      } else {
        $stickyTableHead.removeClass("sticky");
      }
    }
  }

  /*Nav*/
  $(".js-open-nav").click(function () {
    if (!$html.hasClass("opened-nav")) {
      $html.addClass("opened-nav");

      $("#nav > ul > li").each(function () {
        if (
          $(this).hasClass("active") &&
          $(this).find(".dropdown").length > 0
        ) {
          $(this).addClass("opened-dropdown").find(".dropdown").show();
        }
      });

      /*Old ios version bug fix*/
      /*if ($html.hasClass("ios") && $html.hasClass("touchevents")) {
        $("html, body").animate(
          {
            scrollTop: scrolledY + 1,
          },
          50
        );
      }*/
    } else {
      $html.removeClass("opened-nav");
      setTimeout(function () {
        $("#nav li").removeClass("opened-dropdown").find(".dropdown").hide();
      }, 300);
    }
  });

  /*Quick menu*/
  var $quickMenu = undefined;

  function quickMenuFix() {
    if (windowWidth > 991) {
      var width = 0;
      $quickMenu.find("a").each(function () {
        if (width < $(this).outerWidth()) {
          width = $(this).outerWidth();
        }
      });
      $quickMenu.find("a").width(width);
    } else {
      $quickMenu.find("a").css("width", "auto");
    }
  }
  function quickMenuInit() {
    if ($quickMenu == undefined) {
      $quickMenu = $(".quick-menu");
      var $preview = $quickMenu.find(".quick-menu-preview");
      var $dropdown = $quickMenu.find(".qucik-menu-dropdown");
      var $firstChild = $dropdown.find("a").eq(0);
      var href = $firstChild.attr("href");
      var target = $firstChild.attr("target");
      if (href) {
        $preview.attr("href", href);
      }
      if (target) {
        $preview.attr("target", target);
      }
      $preview.html($firstChild.html());
      $firstChild.remove();

      //quickMenuFix();
    }
  }

  if ($(".quick-menu").length > 0) {
    quickMenuInit();
  }

  /*Index slider*/
  var indexSlider = undefined;
  function indexSliderInit() {
    if (indexSlider == undefined) {
      indexSlider = new Swiper(".index-slider .swiper-container", {
        slidesPerView: 1,
        loop: true,
        autoplay: {
          delay: 10000,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        /*on: {
          init: function () {
            console.log("swiper initialized");
            var currentVideo = $(
              "[data-swiper-slide-index=" + this.realIndex + "]"
            ).find("video");
            currentVideo.trigger("play");
          },
        },*/
      });

      /*var sliderVideos = $(".swiper-slide video");

      indexSlider.on("slideChange", function () {
        console.log("slide changed");
        
        sliderVideos.each(function (index) {
          this.currentTime = 0;
        });
        
        var prevVideo = $(
          "[data-swiper-slide-index=" + this.previousIndex + "]"
        ).find("video");
        var currentVideo = $(
          "[data-swiper-slide-index=" + this.realIndex + "]"
        ).find("video");
        prevVideo.trigger("stop");
        currentVideo.trigger("play");
      });*/

      /*var v = document.getElementsByTagName("video")[0];

      indexSlider.on("slideChange", function () {
        console.log("a");
        $("video").trigger("stop");
      });

      v.addEventListener(
        "canplay",
        function () {
          console.log("canplay");
          $("video").trigger("play");
          indexSlider.autoplay.stop();
        },
        true
      );

      v.addEventListener(
        "ended",
        function () {
          console.log("ended");
          indexSlider.autoplay.start();
        },
        true
      );*/
    }
  }

  if ($(".index-slider").length > 0) {
    indexSliderInit();
  }

  /*regular slider*/
  function sliderInit() {
    $(".tp-slider .swiper-container").each(function (index) {
      var slider = new Swiper($(this)[0], {
        spaceBetween: 30,
        slidesPerView: "auto",
        speed: 400,
        loop: false,
        centeredSlides: false,
        grabCursor: true,
        pagination: {
          el: $(this).parents(".tp-slider").find(".swiper-pagination"),
          clickable: true,
        },
        navigation: {
          prevEl: $(this).parents(".tp-slider").find(".swiper-btn-prev"),
          nextEl: $(this).parents(".tp-slider").find(".swiper-btn-next"),
        },
        on: {
          transitionStart: function () {
            if (!slider.isEnd) {
              slider.$el.removeClass("reached-end");
            }
          },
          transitionEnd: function () {
            if (slider.isEnd) {
              slider.$el.addClass("reached-end");
            }
          },
          reachBeginning: function () {
            if ($html.hasClass("no-touchevents")) {
              $body.removeClass("pointer");
            }
          },
          reachEnd: function () {
            if ($html.hasClass("no-touchevents")) {
              $body.removeClass("pointer");
            }
          },
        },
        breakpoints: {
          576: {
            spaceBetween: 65,
          },
        },
      });
    });
  }

  if ($(".tp-slider").length > 0) {
    sliderInit();
  }

  /*cc slider*/
  function ccSliderInit() {
    $(".tp-cc-slider .swiper-container").each(function (index) {
      var ccSlider = new Swiper($(this)[0], {
        effect: "fade",
        loop: true,
        autoplay: {
          delay: 5000,
        },
        pagination: {
          el: $(this).parents(".tp-cc-slider").find(".swiper-pagination"),
          clickable: true,
        },
      });
    });
  }

  if ($(".tp-cc-slider").length > 0) {
    ccSliderInit();
  }

  /*inputmask*/
  if ($("[data-mask]").length > 0) {
    $("[data-mask]").each(function () {
      var $this = $(this);
      if ($this.data("mask").length > 0) {
        $this.inputmask({
          mask: $this.data("mask"),
        });
      }
    });
  }

  /*select2*/
  if ($(".tp-select__select2").length > 0) {
    $(".tp-select__select2").each(function () {
      var options = {
        theme: "flat",
        language: "tr",
        dropdownParent: $("[data-scroll-container]"),
      };
      if ($(this).data("no-search")) {
        options.minimumResultsForSearch = Infinity;
      }
      $(this).select2(options);

      /*document.addEventListener(
        "scroll",
        function (event) {
          console.log(event.target.id);
          if ($(event.target).hasClass("select2-results__options")) {
            console.log("scrolling");
            if (locoScroll) {
              locoScroll.stop();
            }
          }
        },
        true
      );*/

      $(this).on("select2:open", function (e) {
        var $this = $(this);
        $(".select2-container--open")
          .on("mouseenter", function () {
            if (locoScroll) {
              locoScroll.stop();
            }
          })
          .on("mouseleave", function () {
            if (locoScroll) {
              locoScroll.start();
            }
          });

        $body.on("touchstart", function (e) {
          var $target = $(e.target);
          if (!$target.closest(".select2-container--open").length > 0) {
            $this.select2("close");
            if (locoScroll) {
              locoScroll.start();
            }
          } else {
            if (locoScroll) {
              locoScroll.stop();
            }
          }
        });
      });

      $(this).on("select2:close", function (e) {
        if (locoScroll) {
          locoScroll.start();
        }
      });
    });
  }

  /*select2 populator*/

  function initSelectPopulator() {
    var $populator = $("[data-select2-populate]");
    var target = $populator.data("select2-populate");
    var $target = $(".tp-select__select2[name='" + target + "']");
    var $parent = $target.parents(".tp-select");
    var targetOriginalText = $target.text();
    var $loader = $(
      '<div class="tp-select__loader"><i class="las la-spinner la-spin"></i></div>'
    );

    function resultSuccess(data) {
      if (data) {
        $(data).each(function (i, item) {
          var newOption = new Option(item.text, item.id, false, false);
          $target.append(newOption);
        });
      }
      $target.trigger("change");
      $target.prop("disabled", false);
      $loader.remove();
    }

    function resultError(err) {
      //console.log(err);
      $target.prop("disabled", false);
      $loader.remove();
    }

    function clearItems() {
      $target.html("");
      var newOption = new Option(targetOriginalText, "", false, false);
      $target.append(newOption);
      $target.trigger("change");
    }

    $populator.on("change", function () {
      clearItems();
      var formData = {};
      formData.city = $(this).val();
      if (formData.city) {
        $target.prop("disabled", true);
        $parent.prepend($loader);
        window.submitDistrictForm(formData, resultSuccess, resultError);
      }
    });
  }

  if ($("[data-select2-populate]").length > 0) {
    initSelectPopulator();
  }

  /*index prices*/
  window.setIndexPrices = function (district) {
    var $priceList = $("#indexPriceList");
    var $priceListInfo = $("#indexPriceListInfo");
    var $loader = $(
      '<div class="price-list__loader"><i class="las la-spinner la-spin la-2x"></i></div>'
    );
    var $city = $("#indexPricesCity");
    var formData = {};
    formData.city = $city.val();
    formData.district = district;

    function resultSuccess(data) {
      if (data) {
        var unit = "TL/LT";
        if (data.unit) {
          unit = data.unit;
        }

        if (data.info) {
          $priceListInfo.html(data.info);
        }

        if (data.fields && data.rows) {
          var priceListHtml = "";
          $(data.fields).each(function (i, field) {
            priceListHtml +=
              '<div class="price-list-item">\
                <span class="price-list-item-title">' +
              field +
              '</span>\
                <span class="price-list-item-value animated-counter"\
                  data-final-count="' +
              data.rows[0][i] +
              '">0.00</span>\
                <span class="price-list-item-unit">' +
              unit +
              "</span>\
              </div>";
          });
          $priceList.html(priceListHtml);
          if ($(".animated-counter").length > 0) {
            animatedCounter();
          }
        }
      } else {
        $priceList.html("");
        $priceListInfo.html("");
      }
    }

    function resultError(err) {
      //console.log(err);
      $priceList.html("");
      $priceListInfo.html("");
    }

    $priceList.prepend($loader);

    window.submitFuelPriceForm(
      formData,
      "indexPriceList",
      resultSuccess,
      resultError
    );
  };

  /*index stations button*/
  window.setStationsButton = function (city) {
    var $btn = $("#indexStationsBtn");
    if ($btn.length > 0) {
      var path = $btn.data("path");
      $btn.attr("href", path + city);
    }
  };

  /*select2 trigger*/

  function initSelectTrigger() {
    var $triggers = $("[data-select2-trigger]");

    $triggers.each(function () {
      var $trigger = $(this);
      var target = $trigger.data("select2-trigger");

      $trigger.on("change", function () {
        var val = $(this).val();
        if (val) {
          window[target](val);
        }
      });
    });
  }

  if ($("[data-select2-trigger]").length > 0) {
    initSelectTrigger();
  }

  /*Animated counter*/
  function animatedCounter() {
    $(".animated-counter").each(function () {
      var $this = $(this),
        countTo = $this.attr("data-final-count");
      if (
        !$this.hasClass("finished") &&
        $this.parents("[data-scroll]").hasClass("is-inview")
      ) {
        $({ countNum: $this.text() }).animate(
          { countNum: countTo },
          {
            duration: 1000,
            easing: "swing",
            step: function () {
              $this.text((parseFloat(this.countNum) + 0.01).toFixed(2));
            },
            complete: function () {
              $this.text(this.countNum);
            },
          }
        );
        $this.addClass("finished");
      }
    });
  }
  if ($(".animated-counter").length > 0) {
    animatedCounter();
  }

  /*flip*/
  function flip() {
    $(".stations-card").mouseup(function (e) {
      if (e.which != 1) return false;
      var $target = $(e.target);
      if (
        !$target.closest(".tp-select").length &&
        !$target.closest(".btn").length
      ) {
        $(this).toggleClass("flip");
      }
    });
  }
  if ($(".stations-card").length > 0) {
    flip();
  }

  /*contacts map*/
  /*called from async google map script !! dont remove window !!*/
  var map;
  var markers = [];

  window.initMap = function initMap() {
    if ($("#map").length < 1) {
      return;
    }

    var defaultLatLng = {
      lat: 39.0101123,
      lng: 30.6897679,
    };

    var defaultZoom = 6;

    map = new google.maps.Map($("#map")[0], {
      center: defaultLatLng,
      zoom: defaultZoom,
      scrollwheel: false,
    });

    function addMarker(location) {
      var marker = new google.maps.Marker({
        position: location,
        map: map,
      });
      markers.push(marker);
    }

    // Sets the map on all markers in the array.
    function setMapOnAll(map) {
      for (var i = 0; i < markers.length; i++) {
        markers[i].setMap(map);
      }
    }

    // Removes the markers from the map, but keeps them in the array.
    function clearMarkers() {
      setMapOnAll(null);
    }

    // Shows any markers currently in the array.
    function showMarkers() {
      setMapOnAll(map);
    }

    // Deletes all markers in the array by removing references to them.
    function deleteMarkers() {
      clearMarkers();
      markers = [];
    }

    function setMap(activeElem) {
      deleteMarkers();

      var selectedLatLng = defaultLatLng;
      var selectedZoom = defaultZoom;

      if (activeElem.data("zoom")) {
        selectedZoom = activeElem.data("zoom");
      }

      if (activeElem.data("lat") && activeElem.data("lng")) {
        selectedLatLng = {
          lat: activeElem.data("lat"),
          lng: activeElem.data("lng"),
        };

        addMarker(selectedLatLng);
      }

      map.setCenter(selectedLatLng);
      map.setZoom(selectedZoom);
    }

    if ($(".tp-contact .tp-select__select2").length > 0) {
      var $prevSelection;

      $(".tp-contact .tp-select__select2").on("select2:select", function (e) {
        if ($prevSelection) {
          $prevSelection.removeClass("active");
        }
        var $optionSelected = $(e.params.data.element, this);
        setMap($optionSelected);
        $optionSelected.tab("show");
        $prevSelection = $optionSelected;
      });

      setMap($(".tp-contact .tp-select__select2 option:first-child"));
    }
  };

  /*forms*/
  function initForms() {
    function validate($form) {
      var hasError = false;

      function getErrorMsg($elem) {
        return $elem[0].validationMessage;
      }

      $form
        .find(
          ".form-control:not(.no-validate):not(.custom-validate):invalid, .custom-file-input:not(.no-validate):not(.custom-validate):invalid"
        )
        .each(function (index) {
          $(this)
            .parent()
            .find(".invalid-feedback")
            .text(getErrorMsg($(this)));
          if (index === 0) {
            var $target = $(this).closest(".form-group");
            if (locoScroll) {
              var offset =
                ($target.height() +
                  parseInt($target.css("marginTop").replace("px", "")) +
                  parseInt($target.css("marginBottom").replace("px", ""))) *
                -1;
              locoScroll.scrollTo($target[0], offset);
            } else {
              $("html, body").animate(
                {
                  scrollTop: $target.offset().top,
                },
                500
              );
            }
          }
          hasError = true;
        });

      return hasError;
    }

    $(".form.needs-validation").each(function () {
      var $form = $(this),
        $wrapper = $form.parents(".form-wrapper"),
        formId = $form.attr("id"),
        $btnSubmit = $form.find(".btn-submit-form"),
        $formResultSuccess = $wrapper.find(".form-result.text-success"),
        $formResultError = $wrapper.find(".form-result.text-danger"),
        $captcha = $form.find(".captcha"),
        $captchaCanvas = $form.find(".captcha-canvas"),
        $captchaRefresh = $form.find(".captcha-refresh"),
        btnSubmitOriginalHtml = $btnSubmit.html(),
        $loader = $(
          '<div class="form__loader"><i class="las la-spinner la-spin"></i></div>'
        );

      //file
      var $fileUpload = $form.find("input[type='file']");
      var labelOriginalText = "";
      if ($fileUpload.length > 0) {
        var $label = $fileUpload.siblings(".custom-file-label");
        labelOriginalText = $label.text();
        var max = $fileUpload.data("max");
        var error = "File size exceeds " + max + " MB.";
        if ($fileUpload.data("error")) {
          error = $fileUpload.data("error");
        }

        var max = 2;
        var ext = "*";
        var maxError = "The file size can not exceed " + max + "MB.";
        var extError =
          "Please select correct file format. (" + ext.replace("|", ", ") + ")";

        var dataRules =
          $fileUpload.data("rules") &&
          Object.keys($fileUpload.data("rules")).length > 0
            ? $fileUpload.data("rules")
            : {};
        var rules = Object.assign(
          {},
          {
            max: max,
            ext: ext,
            maxError: maxError,
            extError: extError,
          },
          dataRules
        );

        function checkFileInput() {
          var fileName = $fileUpload.val().split("\\").pop();

          if (fileName) {
            $label.addClass("selected");
            $label.html(fileName);
          } else {
            $label.removeClass("selected");
            $label.html(labelOriginalText);
          }

          var errorMsg = "";
          if ($fileUpload[0].files[0]) {
            var size = $fileUpload[0].files[0].size;
            var maxSize = 1024 * 1024 * rules.max;

            if (size > maxSize) {
              errorMsg = rules.maxError;
            }

            if (rules.ext !== "*") {
              var regex = new RegExp("(.*?).(" + rules.ext + ")$");
              if (!regex.test(fileName)) {
                errorMsg = rules.extError;
              }
            }
          }

          $fileUpload[0].setCustomValidity(errorMsg);

          $fileUpload
            .parent()
            .find(".invalid-feedback")
            .text($fileUpload[0].validationMessage);
        }
        $fileUpload.on("change", checkFileInput);
      }

      var canvasStyle = {
        width: parseInt($captchaCanvas.attr("width")),
        height: parseInt($captchaCanvas.attr("height")),
        textBaseline: "middle",
        font: "20px Arial",
        textAlign: "left",
        fillStyle: "#e30613",
      };
      var captchaVal;

      function generateCaptcha() {
        captchaVal = Helpers.generateRandomInteger(1000, 9999);
        captchaVal = captchaVal.toString();

        var ctx = $captchaCanvas[0].getContext("2d");
        ctx.textBaseline = canvasStyle.textBaseline;
        ctx.font = canvasStyle.font;
        ctx.textAlign = canvasStyle.textAlign;
        ctx.fillStyle = canvasStyle.fillStyle;

        ctx.clearRect(0, 0, canvasStyle.width, canvasStyle.height);

        for (var i = 0; i < captchaVal.length; i++) {
          var rot = (Math.PI / 180) * Helpers.generateRandomInteger(-20, 20);
          var posY =
            Math.round(canvasStyle.height * 0.5) +
            Helpers.generateRandomInteger(-5, 5);
          var posX = i * 22 + 10;

          var size = ctx.measureText(captchaVal[i]);
          ctx.save();
          var tx = posX + size.width / 2;
          var ty = posY;
          ctx.translate(tx, ty);
          ctx.rotate(rot);
          ctx.translate(-tx, -ty);
          ctx.fillText(captchaVal[i], posX, posY);
          ctx.restore();
        }

        ctx.resetTransform();

        var x, y;
        for (y = 0; y < canvasStyle.height; y++) {
          for (x = 0; x < canvasStyle.width; x++) {
            var number = Math.floor(Math.random() * 200);

            ctx.fillStyle =
              "rgba(" + number + "," + number + "," + number + "," + 0.4 + ")";
            ctx.fillRect(x, y, 1, 1);
          }
        }
      }

      function checkCaptcha() {
        var $this = $captcha;
        var value = $this.val();
        var error = "Captcha code does not match.";
        if ($this.data("error")) {
          error = $this.data("error");
        }
        if (value.length > 0 && value !== captchaVal) {
          $this[0].setCustomValidity(error);
        } else {
          $this[0].setCustomValidity("");
        }
        $this
          .parent()
          .find(".invalid-feedback")
          .text($this[0].validationMessage);
      }

      $captcha.on("input", checkCaptcha);

      generateCaptcha();

      $captchaRefresh.on("click", function (e) {
        e.preventDefault();
        generateCaptcha();
        checkCaptcha();
      });

      function resultCallback(isSuccess) {
        $btnSubmit.html(btnSubmitOriginalHtml).addClass("d-none");

        if (isSuccess) {
          $form.find(".form-control").val("");
          if ($fileUpload.length > 0) {
            $fileUpload.val("");
            var $label = $fileUpload.siblings(".custom-file-label");
            $label.html(labelOriginalText);
          }
          $formResultSuccess.removeClass("d-none");
        } else {
          $formResultError.removeClass("d-none");
        }

        setTimeout(function () {
          generateCaptcha();
          $loader.remove();
          $formResultSuccess.addClass("d-none");
          $formResultError.addClass("d-none");
          $btnSubmit.removeClass("d-none");
        }, 3000);
      }

      $btnSubmit.on("click", function (e) {
        e.preventDefault();

        $form.removeClass("was-validated");

        if (validate($form)) {
          $form.addClass("was-validated");
        } else {
          var serializedArray = $form
            .find(".form-control:not(.no-serialize)")
            .serializeArray();

          var formData;
          if ($fileUpload.length > 0 && $fileUpload[0].files[0]) {
            formData = new FormData();
            formData.append("file", $fileUpload[0].files[0]);
            $(serializedArray).each(function (index, obj) {
              formData.append(obj.name, obj.value);
            });
          } else {
            formData = {};
            $(serializedArray).each(function (index, obj) {
              formData[obj.name] = obj.value;
            });
          }

          $btnSubmit.find("i").attr("class", "las la-spinner la-spin ml-2");
          $wrapper.append($loader);

          window.submitForm(formData, formId, resultCallback);
        }
      });
    });
  }

  if ($(".form.needs-validation").length > 0) {
    initForms();
  }

  /*prices view*/
  function initFuelPricesView() {
    var $view = $(".tp-prices"),
      viewId = $view.attr("id"),
      $form = $view.find(".form"),
      $listingType = $view.find("[name='listingType']"),
      $datePickerCol = $view.find("#datePickerCol"),
      $fuelChoose = $view.find("#fuelChoose"),   
    $btnSubmit = $view.find(".btn-submit-form"),
      $error = $view.find("#form-error"),
      $city = $view.find("[name='city']"),
      $district = $view.find("[name='district']"),    
      $pricesTable = $view.find("#pricesTable"),
      $exportButtons = $($pricesTable.data("buttons")),
      tableExportOptions = {
        exportButtons: false,
        filename: $pricesTable.data("filename"),
      },
      $dataInfo = $pricesTable.find("#dataInfo"),
      $dataFields = $pricesTable.find("#dataFields"),
      $dataRows = $pricesTable.find("#dataRows"),
      btnSubmitOriginalHtml = $btnSubmit.html(),
      lastCalledFormData = null;

    var fpOptions = { mode: "range", maxDate: "today", dateFormat: "d.m.Y" };
    var datePickerInput = document.querySelector("#datePicker");
    var fp = flatpickr(datePickerInput, fpOptions);

    $exportButtons.find(".export-btn").on("click", function (e) {
      e.preventDefault();
      var $this = $(this);
      var type = $this.data("type");
      var tableInstance = $pricesTable.tableExport(tableExportOptions);
      var exportData = tableInstance.getExportData()["pricesTable"][type];
      tableInstance.export2file(
        exportData.data,
        exportData.mimeType,
        exportData.filename,
        exportData.fileExtension
      );
    });

    function createTable(data) {
      if (data.info) {
        $dataInfo.html(data.info);
      }

      if (data.fields) {
        var fieldsHtml = "";
        $(data.fields).each(function (i, field) {
          fieldsHtml += "<td>" + field + "</td>";
        });
        $dataFields.html(fieldsHtml);
      }

      if (data.rows) {
        var rowsHtml = "";
        $(data.rows).each(function (i, row) {
          rowsHtml += "<tr>";

          $(row).each(function (i, item) {
            rowsHtml += "<td>" + item + "</td>";
          });

          rowsHtml += "</tr>";
        });
        $dataRows.html(rowsHtml);
        $exportButtons.removeClass("d-none");
      }
      if (locoScroll) {
        locoScroll.update();
      }
    }

    function resultSuccess(data) {
      $btnSubmit.html(btnSubmitOriginalHtml);
      if (data) {
        createTable(data);
        Helpers.setUrlParameter($.param(lastCalledFormData));
      }
      lastCalledFormData = null;
    }

    function resultError(err) {
      $btnSubmit.html(btnSubmitOriginalHtml);
      if (locoScroll) {
        locoScroll.update();
      }
      lastCalledFormData = null;
    }

    function resetForm() {
      fp.clear();
      $city.val(null).trigger("change");
      $district.val(null).trigger("change");
      $error.addClass("d-none");
    }

    function formOnChange() {
      $error.addClass("d-none");
    }

    $city.on("change", formOnChange);

    $district.on("change", formOnChange);

    fp.config.onChange.push(formOnChange);

    function toggleDatePicker() {
      if (listingType === "0") {
        $datePickerCol.hide();
   $fuelChoose.hide();
    } else {
        $datePickerCol.show();
   $fuelChoose.show();
     }
    }

    var listingType = $listingType.val();
    toggleDatePicker();

    $listingType.on("change", function () {
      listingType = $(this).val();
      toggleDatePicker();

      $dataInfo.html("");
      $dataFields.html("");
      $dataRows.html("");

      $exportButtons.addClass("d-none");

      resetForm();

      if (locoScroll) {
        locoScroll.update();
      }
    });

    /*setTimeout(function () {
      window.submitFuelPriceForm({}, resultSuccess, resultError);
    }, 500);*/

    $btnSubmit.on("click", function (e) {
      e.preventDefault();

      var formData = {};
      var dateStrings = ["startDate", "endDate"];
      formData.type = listingType;
      formData.city = $city.val();
      if ($district.length > 0) {
        formData.district = $district.val();
      }
  if($choose.length > 0) {
    formdata.choose = $choose.val();
  }
    if (listingType !== "0") {
        $(fp.selectedDates).each(function (i, item) {
          formData[dateStrings[i]] = fp.formatDate(item, "Y-m-d");
        });
      }

      var isValid = true;
      if (!formData.city || ($district.length > 0 && !formData.district)) {
        isValid = false;
      }

      if (
        listingType !== "0" &&
        (!formData[dateStrings[0]] || !formData[dateStrings[1]])
      ) {
        isValid = false;
      }

      if (!isValid) {
        $error.removeClass("d-none");
      } else {
        $error.addClass("d-none");

        $dataInfo.html("");
        $dataFields.html("");
        $dataRows.html("");

        $exportButtons.addClass("d-none");

        $btnSubmit.find("i").attr("class", "las la-spinner la-spin ml-2");

        lastCalledFormData = formData;
        window.submitFuelPriceForm(
          formData,
          viewId,
          resultSuccess,
          resultError
        );
      }
    });
  }

  if ($(".tp-prices").length > 0) {
    initFuelPricesView();
  }

  /*prices view*/
  /*function initLpgPricesView() {
    var $view = $("#lpgPricesView"),
      $pricesTable = $view.find("#pricesTable"),
      $exportButtons = $($pricesTable.data("buttons")),
      tableExportOptions = {
        exportButtons: false,
        filename: $pricesTable.data("filename"),
      };

    $exportButtons.find(".export-btn").on("click", function (e) {
      e.preventDefault();
      var $this = $(this);
      var type = $this.data("type");
      var tableInstance = $pricesTable.tableExport(tableExportOptions);
      var exportData = tableInstance.getExportData()["pricesTable"][type];
      tableInstance.export2file(
        exportData.data,
        exportData.mimeType,
        exportData.filename,
        exportData.fileExtension
      );
    });
  }

  if ($("#lpgPricesView").length > 0) {
    initLpgPricesView();
  }*/

  /*stations view*/
  function initStationsView() {
    var $view = $("#stationsView"),
      $map = $view.find("#svgMap"),
      $citySelect = $view.find("[name='city']"),
      $resultView = $view.find(".result"),
      $resultLoader = $view.find(".result-loader"),
      $resultError = $view.find(".result-error"),
      $resultError = $view.find(".result-error"),
      $resultRefreshBtn = $view.find(".result-refresh"),
      $mapPaths = $map.find("path"),
      $mapTooltip = $view.find("#svgTooltip"),
      $mapTooltipCopy = null,
      initialCity = $view.data("initial-city"),
      formOnProgress = false,
      lastFormData = null;

    function focusToResult() {
      var $target = $resultView;
      if (locoScroll) {
        var offset = $(".tp-header").height() * -1;
        locoScroll.scrollTo($target[0], offset);
      } else {
        $("html, body").animate(
          {
            scrollTop: $target.offset().top,
          },
          500
        );
      }
    }

    var itemTemp =
      '<div class="row">\
    <div class="col">\
      <div class="item">\
        <div class="content-wrapper">\
          <div class="content" data-match-height="content">\
            <span class="info large">\
            {{lisenceNo}} - <i class="las la-gas-pump mx-1"></i>{{type}}\
            </span>\
            <h4 class="title" data-match-height="title">{{title}}</h4>\
            <p class="desc" data-match-height="desc">{{address}}</p>\
            <a href="{{mapUrl}}" target="_blank"\
              class="btn btn-secondary btn-sm">\
              <i class="las la-map-marker mr-1"></i>Haritada Göster\
            </a>\
          </div>\
        </div>\
      </div>\
    </div>\
  </div>';

    function createTable(data) {
      var resultHtml = "";
      $(data).each(function (i, item) {
        var itemHtml = itemTemp
          .replace("{{lisenceNo}}", item.lisenceNo)
          .replace("{{type}}", item.type)
          .replace("{{title}}", item.title)
          .replace("{{address}}", item.address)
          .replace("{{mapUrl}}", item.mapUrl);
        var $item = $(itemHtml);
        if (!item.mapUrl) {
          $item.find("a").remove();
        }
        resultHtml += $item[0].outerHTML;
      });

      $resultView.html(resultHtml);

      initGridsAndLists();
    }

    function resultSuccess(data) {
      if (data) {
        createTable(data);
      }
      $resultLoader.addClass("d-none");
      formOnProgress = false;

      if (locoScroll) {
        locoScroll.update();
      }
    }

    function resultError(err) {
      $resultLoader.addClass("d-none");
      $resultError.removeClass("d-none");
      formOnProgress = false;

      if (locoScroll) {
        locoScroll.update();
      }
    }

    function submitForm(id) {
      var formData = { id: id };
      lastFormData = formData;
      $resultView.html("");
      $resultError.addClass("d-none");
      $resultLoader.removeClass("d-none");
      focusToResult();
      window.submitStationsForm(formData, resultSuccess, resultError);
    }

    $resultRefreshBtn.on("click", function (e) {
      e.preventDefault();
      if (lastFormData) {
        submitForm(lastFormData.id);
      }
    });

    function moveTooltip($element, x, y) {
      var relativeXPosition = x - $map.offset().left;
      var relativeYPosition = y - $map.offset().top;
      $element.css({
        top: relativeYPosition - 50 + "px",
        left: relativeXPosition + "px",
      });
    }

    $(window).on(
      "resize orientationchange",
      Helpers.throttle(function (event) {
        if ($mapTooltipCopy) {
          var $target = $map.find("path.active");
          moveTooltip(
            $mapTooltipCopy,
            $target.parent().offset().left +
              $target.parent()[0].getBoundingClientRect().width * 0.5,
            $target.parent().offset().top +
              $target.parent()[0].getBoundingClientRect().height * 0.5
          );
        }
      }, 300)
    );

    $citySelect.on("change", function () {
      selectCity($citySelect.val(), true, false);
    });

    function selectCity(id, setPosition, isInitial) {
      var founded = false;

      $mapPaths.each(function () {
        if (
          $(this).parent().data("plakakodu").toString() === id.toString() &&
          !founded &&
          !formOnProgress
        ) {
          $mapPaths.removeClass("active");

          if ($mapTooltipCopy) {
            $mapTooltipCopy.remove();
          }

          $(this).parent().find("path").addClass("active");
          var name = $(this).parent().data("iladi");
          $mapTooltip.html("<div>" + name + "</div>");
          founded = true;

          if (setPosition) {
            moveTooltip(
              $mapTooltip,
              $(this).parent().offset().left +
                $(this).parent()[0].getBoundingClientRect().width * 0.5,
              $(this).parent().offset().top +
                $(this).parent()[0].getBoundingClientRect().height * 0.5
            );
          }

          $mapTooltipCopy = $mapTooltip.clone();
          $mapTooltipCopy.removeAttr("id");
          $mapTooltip.after($mapTooltipCopy);
          $mapTooltip.html("");

          if (!isInitial) {
            Helpers.setUrlParameter("city=" + id);
            formOnProgress = true;
            submitForm(id);
          }
        }
      });
    }

    var urlCity = Helpers.getUrlParameter("city");
    if (urlCity) {
      selectCity(urlCity, true, true);
      $citySelect.val(urlCity).trigger("change.select2");
    } else if (initialCity) {
      selectCity(initialCity, true, true);
      $citySelect.val(initialCity).trigger("change.select2");
      Helpers.setUrlParameter("city=" + initialCity);
    }

    $map.on("mouseover", function (e) {
      if ($(e.target).prop("tagName") === "path") {
        if ($(e.target).hasClass("active")) {
          return;
        }
        var name = $(e.target).parent().data("iladi");
        $(e.target).parent().find("path").addClass("hover");
        $mapTooltip.html("<div>" + name + "</div>");
      }
    });

    $map.on("mouseout", function () {
      $mapTooltip.html("");
      $mapPaths.removeClass("hover");
    });

    $map.on("mousemove", function (e) {
      moveTooltip($mapTooltip, e.pageX, e.pageY);
    });

    $map.on("click", function (e) {
      if ($(e.target).prop("tagName") === "path") {
        if ($(e.target).hasClass("active")) {
          return;
        }
        var id = $(e.target).parent().data("plakakodu").toString();

        selectCity(id, false, false);
        $citySelect.val(id).trigger("change.select2");
      }
    });
  }

  if ($("#stationsView").length > 0) {
    initStationsView();
  }

  /*Equal height - no need anymore*/
  /*if ($('.tp-slider [data-match-height="title"]').length > 0) {
    $('.tp-slider [data-match-height="title"]').matchHeight();
  }
  if ($('.tp-slider [data-match-height="desc"]').length > 0) {
    $('.tp-slider [data-match-height="desc"]').matchHeight();
  }
  if ($('.tp-cc-slider [data-match-height="title"]').length > 0) {
    $('.tp-cc-slider [data-match-height="title"]').matchHeight();
  }
  if ($('.tp-cc-slider [data-match-height="desc"]').length > 0) {
    $('.tp-cc-slider [data-match-height="desc"]').matchHeight();
  }
  if ($('.tp-cc-slider [data-match-height="content"]').length > 0) {
    $('.tp-cc-slider [data-match-height="content"]').matchHeight();
  }*/

  /*Accordion*/
  function initAccordion() {
    $(".accordion").each(function () {
      var $this = $(this),
        $collapse = $this.find(".collapse");

      $collapse.first().prev().addClass("active");

      $collapse.on("show.bs.collapse", function () {
        $(this).prev().addClass("active");
      });

      $collapse.on("hide.bs.collapse", function () {
        $(this).prev().removeClass("active");
      });

      $collapse.on("shown.bs.collapse", function () {
        if (locoScroll) {
          //locoScroll.update();
          var offset = $(".tp-header").height() * -1;
          locoScroll.scrollTo($(this).parent()[0], offset);
        }
      });

      $collapse.on("hidden.bs.collapse", function () {
        if (locoScroll) {
          locoScroll.update();
        }
      });
    });
  }

  if ($(".accordion").length > 0) {
    initAccordion();
  }

  /*GridAndLists*/
  function initGridsAndLists() {
    $(".tp-grid-and-list").each(function () {
      var $gridOrList = $(this),
        $items = $gridOrList.find(".item"),
        $bg = $gridOrList.find(".item-bg"),
        isGrid = $gridOrList.hasClass("grid"),
        bgSpace = { top: isGrid ? 100 : 0, left: 0, right: 0, bottom: 0 };

      $items.on("mouseover", function () {
        var $item = $(this);

        bgSpace.bottom =
          parseInt($item.css("marginBottom").replace("px", "")) * -1;

        var $parent = $item.parent();
        var pos = $parent.position();
        var x =
          pos.left +
          parseInt($parent.css("paddingLeft").replace("px", "")) +
          bgSpace.left;
        var y =
          pos.top +
          parseInt($parent.css("paddingTop").replace("px", "")) +
          bgSpace.top;
        var width = $parent.width() - (bgSpace.left + bgSpace.right);
        var height = $parent.height() + (bgSpace.top * -1 + bgSpace.bottom);

        $item.addClass("active");
        $bg.addClass("active");

        $bg[0].style.width = width + "px";
        $bg[0].style.height = height + "px";
        $bg[0].style.transform =
          "translateX(" + x + "px ) translateY(" + y + "px)";
      });

      $items.on("mouseleave", function () {
        $items.removeClass("active");
        $bg.removeClass("active");
      });
    });
  }

  if ($(".tp-grid-and-list").length > 0) {
    initGridsAndLists();
  }

  window.onunload = function () {};
  window.onpageshow = function () {};
  window.addEventListener("beforeunload", function () {});

  /*Window load*/
  $(window).on("load", function () {
    $.ready.then(function () {
      if ($html.hasClass("preload-page")) {
        setTimeout(function () {
          $html.addClass("page-load");
        }, 5000);
      }

      /*Update scroll*/
      locoScroll = new LocomotiveScroll({
        el: document.querySelector("[data-scroll-container]"),
        smooth: true,
        getDirection: true,
      });

      locoScroll.on("scroll", function (e) {
        stickyHeader(e.scroll.y);

        stickyTableHeader();

        if ($(".animated-counter").length > 0) {
          animatedCounter();
        }

        if (
          $(".tp-select__select2").length > 0 &&
          $(".select2-dropdown").length > 0
        ) {
          $(".tp-select__select2").each(function () {
            $(this).select2("close");
          });
        }
      });
    });
  });

  /*resize*/
  $(window).on(
    "resize orientationchange",
    Helpers.throttle(function (event) {
      windowWidth = Math.max($(window).width(), window.innerWidth);
    }, 300)
  );
})(jQuery);
